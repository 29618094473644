.footer {
	background-color: #f2f2f2;
	padding: 14px 20px;
	font-size: 14px;
	font-weight: 200;
	line-height: 1.57;
	color: rgba($grey, 0.5);
	text-align: center;
	

	@include media('>=tablet') {
		display: flex;
		justify-content: space-between;
		background: $white;
		border-top: 1px solid #f2f2f2;
		position: absolute;
		bottom: 0;
		width: 100%;
		left: 0;
		height: 50px;
		overflow: hidden;
	}

	p {
		margin: 0 0 12px;

		&:last-child {
			margin: 0;
		}

		@include media('>=tablet') {
			margin: 0;
		}
	}
}