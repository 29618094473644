.btn-open {
    position: absolute;
    top: 13px;
    left: 50%;
    margin: 0 0 0 -20px;
    z-index: 10;
    width: 40px;
    height: 38px;
    @include media('>=tablet') {
        display: none;
    }
    &:after,
    &:before,
    span {
        content: '';
        display: block;
        left: 0;
        right: 0;
        top: 50%;
        margin: -1px 0 0;
        height: 3px;
        background: $gold;
        position: absolute;
        transition: all 0.3s ease;
    }
    &:after {
        margin: -16px 0 0;
    }
    &:before {
        margin: 15px 0 0;
    }
    .active-nav & {
        &:after {
            transform: rotate(-45deg);
            margin: 0;
        }
        &:before {
            transform: rotate(45deg);
            margin: 0;
        }
        span {
            opacity: 0;
        }
    }
    .active-nav & {
        background: none;
    }
}

.main-nav {
    @include media('>=tablet') {
        position: absolute;
        top: 190px;
        bottom: 80px;
        left: 40px;
        display: flex;
        align-items: center;
    }
    &__list {
        @extend %listreset;
        font-weight: 600;
        font-size: 14px;
        line-height: 1.86;
        padding: 86px 20px 30px;
        position: fixed;
        background: rgba($black, 0.95);
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        text-transform: uppercase;
        text-align: center;
        display: none;
        .active-nav & {
            display: block;
        }
        @include media('>=tablet') {
            background: none;
            padding: 0;
            text-align: left;
            position: static;
            display: block;
            font-weight: 500;
            height: auto;
        }
    }
    &__item {
        margin: 0 0 30px;
        @include media('>=tablet') {
            margin: 0 0 36px;
            &:last-child {
                margin: 0;
            }
        }
        &.active {
            a {
                color: #8b754e;
                text-decoration: none;
            }
            @include media('>=tablet') {
                a {
                    color: $grey !important;
                    text-decoration: none;
                    position: relative;
                    &:after {
                        width: 20px;
                        height: 2px;
                        background-color: $grey;
                        content: '';
                        left: -40px;
                        top: 50%;
                        margin-top: -1px;
                        position: absolute;
                    }
                }
            }
        }
        a {
            color: #fff;
            text-decoration: none !important;
            position: relative;
            z-index: 9999;
            &:hover {
                color: $gold;
            }
            @include media('>=tablet') {
                color: rgba($grey, 0.5);
                .not-active & {
                    color: rgba($grey, 1);
                }
                &:hover {
                    color: rgba($grey, 1);
                    text-decoration: none;
                }
            }
        }
    }
}