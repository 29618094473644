.header {
    padding: 14px 10px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: $white;
    z-index: 299;
    border: 1px solid #edf0f2;
    @include media('>=tablet') {
        width: 200px;
        height: 100%;
        padding: 60px 0 80px 40px;
        box-shadow: 5px 0 10px 0 rgba($black, 0.05);
        z-index: 100;
    }
}

.logo {
    width: 122px;
    margin: 0 auto;
    @include media('>=tablet') {
        margin: 0 0 83px;
    }
    @include media('>=desktop') {
        margin: 0 0 153px;
    }
    img {
        &.desktop {
            display: none;
        }
        &.mobile {
            display: block;
        }
        @include media('>=tablet') {
            &.mobile {
                display: none;
            }
            &.desktop {
                display: block;
            }
        }
    }
}