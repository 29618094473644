$mq-support: true;
$mq-fixed-value: false;
@import "base/var";
@import "base/mixins";
@import "vendors/include-media";
@import "vendors/normalize";
@import "vendors/swiper";
@import "vendors/slick";
@import "base/forms";
@import "base/typography";
@import "base/helpers";
@import "layout/header";
@import "layout/nav";
@import "layout/footer";
body {
    position: relative;
}

@font-face {
    font-family: 'icomoon';
    src: url("../fonts/icomoon.eot?gwifyc");
    src: url("../fonts/icomoon.eot?gwifyc#iefix") format("embedded-opentype"), url("../fonts/icomoon.ttf?gwifyc") format("truetype"), url("../fonts/icomoon.woff?gwifyc") format("woff"), url("../fonts/icomoon.svg?gwifyc#icomoon") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
  font-family: 'icomoon-add';
  src: url("../fonts/icomoon_add.eot?gwifyc");
  src: url("../fonts/icomoon_add.eot?gwifyc#iefix") format("embedded-opentype"), 
        url("../fonts/icomoon_add.ttf?gwifyc") format("truetype"), 
        url("../fonts/icomoon_add.woff?gwifyc") format("woff"), 
        url("../fonts/icomoon_add.svg?gwifyc#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; 
}

@font-face {
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 100;
  src: local("Exo 2 Thin"), local("Exo2-Thin"), url("../fonts/Exo2-Thin.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2212, U+2215;
}

[class^="icon-"],
[class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

[class^="icon-add-"],
[class*="icon-add-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon-add' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; 
}

.icon-arrow-light{
  transition: all 0.3s ease;
}
.slick-prev:hover .icon-arrow-light{
  margin: 0 -10px;
}

.slick-next:hover .icon-arrow-light{
  margin: 0 10px;
}



.icon-arrow-light:before {
    content: "\e900";
}

.icon-arrow:before {
    content: "\e901";
}

.icon-check:before {
    content: "\e902";
}

.icon-close:before {
    content: "\e903";
}

.icon-close-project:before {
    content: "\e904";
}

.icon-error:before {
    content: "\e905";
}

.icon-fb:before {
    content: "\e906";
}

.icon-if:before {
    content: "\e907";
}

.icon-inst:before {
    content: "\e908";
}

.icon-vk:before {
    content: "\e909";
}

.icon-add-youtube:before {
  content: "\e910";
}

.icon-add-pinterest:before {
  content: "\e911";
}

*,
*:after,
*:before {
    box-sizing: border-box;
}

body {
    font: #{$font-size-base}/#{$line-height-base} 'Exo 2',
    sans-serif;
    margin: 0;
    color: $base-color;
    background: $base-bg;
    &.preview-active {
        height: 100vh;
        overflow: hidden;
        position: relative;
    }
}

.page {
    overflow: hidden;
    width: 100%;
    position: relative;
    padding: 80px 0 0;
    @include media('>=tablet') {
        padding: 0 0 0 200px;
    }
}

.content {
    position: relative;
    @include media('>=tablet') {
        height: 100vh;
    }
}

.visual {
    position: relative;
    height: calc(100vh - 80px);
    margin: 0 0 33px;
    overflow: hidden;
    backface-visibility: hidden;
    @include media('>=tablet') {
        height: 100vh;
        overflow: hidden;
        margin: 0;
        z-index: 99;
    }
    &__logo {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 792px;
        height: 274px;
        opacity: 0.1;
        z-index: 2;
        display: none;
        @include media('>=tablet') {
            display: block;
        }
    }
    .img-holder {
        width: 100%;
        height: 100%;
        overflow: hidden;
        z-index: 200;
        position: relative;
    }
    .img {
        position: absolute;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 50%;
        transition: all 5s ease;
        backface-visibility: hidden;
        transform: scale(1.2);

        &.scale {
          transform: scale(1.2);
        }

        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba($black, 0.75);
        }
    }
    &.active-vis .img {
        transform: scale(1);
    }
    &.section h1 {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 72%;
        width: 90%;
        font-weight: 100;
        font-size: 24px;
        line-height: 1.42;
        text-align: center;
        color: $white;
        z-index: 201;
        display: block;
        margin: 0;
        text-transform: none;
        @include media('>=tablet') {
            font-size: 30px;
            max-width: 580px;
        }
    }
    .btn-next-section {
        position: absolute;
        bottom: 30px;
        left: 50%;
        width: 12px;
        height: 12px;
        border: solid 2px $white;
        border-top: 0;
        border-right: 0;
        transform: rotate(-45deg);
        transform-origin: 0 0;
        animation: arroynext 2s infinite ease-out;
        transition: all 0.3s ease;
        z-index: 204;
        margin: 0 0 0 -8px;
        @include media('>=tablet') {
            bottom: 50px;
            width: 30px;
            height: 30px;
            border: solid 4px $white;
            border-top: 0;
            border-right: 0;
            margin: 0 0 0 -21px;
        }
        &:hover {
            border-color: $gold;
        }
    }
}

.swiper-wrapper {
    @include media('<tablet') {
        // display: block;
    }
}

.section {
    overflow: hidden;
    position: relative;
    margin: 0 0 35px;
    background: #fff;
    &:after {
        content: '';
        width: 120px;
        position: absolute;
        bottom: 0;
        left: 50%;
        margin-left: -60px;
        height: 1px;
        background: $grey;
    }
    &:last-child {
        margin: 0;
    }
    &:last-child:after {
        display: none;
    }
    @include media('>=tablet') {
        //min-height: 100vh;
        margin: 0;
        &:after {
            display: none;
        }
    }
    h1 {
        font-size: 20px;
        line-height: 1.3;
        text-align: center;
        margin: 0 0 35px;
        text-transform: uppercase;
        font-weight: 500;
        width: 100%;
        @include media('>=tablet') {
            display: none;
        }
    }
}

#secondPage-scroll .fp-scroller {
    height: 100%;
}

.portfolio-holder {
    @include media('>=tablet') {
        overflow: hidden;
        height: 100%;
        width: calc(100% + 6px);
        margin: 0 -3px;
        position: relative;
        .mask {
            width: 100%;
            display: flex;
            height: 100%;
        }
    }
}

.portfolio-holder .mask {
  @media (max-width: 839px) {
    flex-direction: column;
  }
}

.portfolio {
    position: relative;
    width: 100%;
    overflow: hidden;
    margin: 0 0 57px;
    @include media('<tablet') {
        width: 100% !important;
    }
    @include media('>=tablet') {
        width: 33%;
        margin: 0;
        flex: 0 0 33.33%;
        background: $black;
        padding: 0 1px;
        transition: all 0.5s ease;
        &:hover {
            cursor: pointer;
            .portfolio__preview:after {
                opacity: 0;
                transition: all 0.5s ease;
            }
            .portfolio__holder {
                opacity: 1;
                bottom: 40px;
            }
        }
        &.preview-active {
            position: static;
        }
    }
    &__preview {
        display: none;
        position: relative;
        height: 100%;
        overflow: hidden;
        img {
            display: block;
            height: 100%;
            max-height: 100vh;
            width: auto;
        }
        @include media('>=tablet') {
            display: block;
        }
    }
    &__gallery {
        position: relative;
        width: 100%;
        overflow: hidden;
        margin: 0 0 13px;
        @include media('>=tablet') {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 99;
            opacity: 0;
            visibility: hidden;
            margin: 0;
            .preview-active .active-block & {
                opacity: 1;
                visibility: visible;
                overflow: hidden;
            }
        }
    }
    &__mask {
        width: 100%;
        overflow: hidden;
        @include media('<tablet') {
          background: #000000;
          height: 100%;
          width: 100%;
        }
        @include media('>=tablet') {
            position: absolute;
            top: 0;
            bottom: 0;
        }
        &:after {
            content: '';
            height: 25%;
            position: absolute;
            left: 0;
            bottom: 0;
            right: 0;
            background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
            @include media('>=tablet') {
                height: 220px;
            }
        }
    }
    &__slideset {
        @extend %listreset;
        width: 100% !important;
        white-space: nowrap;
        font-size: 0;
        line-height: 0;
        @include media('>=tablet') {
            //width: 99999px;
            height: 100vh;
            overflow: hidden;
        }
    }
    &__slide {
        position: relative;
        display: inline-block;
        overflow: hidden;
        background: #000000;
        // width: 100% !important;
        height: auto;
        font-size: 16px;

        @include media('>=tablet') {
            height: 100%;
            height: 100vh;
        }
        img {
            width: 100%;
            height: auto;
            display: block;
        }
    }
    .btn-prev,
    .btn-next {
        position: absolute;
        bottom: 20px;
        left: 15px;
        font-size: 18px;
        line-height: 1;
        color: $white;
        border: 0;
        background: none;
        z-index: 10;
        padding: 0;
        &:focus {
            outline: none;
        }
    }
    .btn-prev {
        transform: rotate(180deg);
        transform-origin: 50% 50%;
    }
    .btn-next {
        left: auto;
        right: 15px;
    }
    &__title {
        font-size: 14px;
        text-transform: uppercase;
        margin: 0 0 8px;
        letter-spacing: 0.6px;
        position: relative;
        z-index: 2;
        @include media('>=tablet') {
            color: $white;
        }
    }
    &__holder {
        padding: 0 20px;
        @include media('>=tablet') {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 20px;
            color: $white;
            z-index: 12;
            opacity: 0;
            transition: all 0.5s ease;
            padding: 0 20px;
            text-shadow: 0.9px 0.3px 0 $black;
            &:after {
                position: absolute;
                content: '';
                bottom: -40px;
                height: 200px;
                left: 0;
                right: 0;
                background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
            }
        }
        @include media('>=desktop') {
            padding: 0 40px;
        }
    }
    &__info {
        overflow: hidden;
        font-weight: 200;
        margin: 0;
        font-size: 14px;
        position: relative;
        z-index: 2;
        @include media('>=tablet') {
            opacity: 0.7;
        }
        dt {
            float: left;
            margin: 0 5px 0 0;
        }
        dd {
            float: left;
            margin: 0 17px 0 0;
            sup {
                font-size: 9px;
            }
        }
    }

    &.swiper-slide > .icon-add-preloader {
      @include media('<tablet') {
        display: none;
      }
    }
}

.swiper-lazy-preloader.icon-add-preloader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000000; 
  animation: none;

}

.swiper-lazy-preloader.icon-add-preloader::after {
  content: '';
  position: absolute;
  background: url('../images/ajax-loader.gif');
  background-size: cover;
  width: 40px;
  height: 40px;
  top: 50%;
  left: 50%;
}

@keyframes anim-rotate {
	0% {
		transform: rotate(0);
	}
	100% {
		transform: rotate(360deg);
	}
}

.close-gallery {
    position: absolute;
    top: 30px;
    right: 0;
    z-index: 100;
    background: none;
    border: 0;
    color: $white;
    font-size: 30px;
    display: none;
    transition: all 0.3s ease;  
    width: 200px;
    height: 100px;

    .icon-close {
      position: absolute;
      right: 30px;
      top: 0;
      transform: rotate(0deg); 
      transition: all 0.3s ease; 
    }

    @include media('>=desktop') {
        font-size: 45px;
    }
    &:focus {
        outline: none;
    }
    .active-block & {
        display: block;
    }
    &:hover .icon-close{
        position: absolute;
        transform: rotate(90deg);
        transition: all 0.3s ease; 
    }
}

@include media('>=tablet') {
    .portfolio__wrap {
        transition: all 0.3s ease;
    }
    .portfolio__wrap.active-block {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 200px;
        width: calc(100vw - 200px);
        z-index: 99;
        transition: all 5s ease;
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s ease;
        &.animation {
            opacity: 1;
            visibility: visible;
            transition: all 0.5s ease;
        }
        .portfolio__slideset:after {
            position: absolute;
            content: '';
            bottom: -50px;
            height: 200px;
            left: 0;
            right: 0;
            background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
        }
        .portfolio__holder {
            width: 45%;
            opacity: 1;
            bottom: 48px;
            z-index: 100;
            &:after {
                bottom: -50px;
                right: -9999px;
                display: none;
            }
        }
        .custom-pagination {
            font-size: 16px;
            font-weight: 300;
            line-height: 1.38;
            letter-spacing: 0.8px;
            color: $white;
            text-shadow: 0.9px 0.3px 0 $black;
            bottom: 48px;
            text-align: center;
            li {
                display: none;
                &.slick-active {
                    display: block;
                }
            }
        }
        .btn-prev,
        .btn-next {
            position: absolute;
            top: 0;
            bottom: 0;
            width: 100px;
            left: 0;
            border: 0;
            z-index: 12;
            background: none;
            background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
            i {
                position: absolute;
                top: 50%;
                left: 50%;
                font-size: 60px;
                background: none;
                color: $white;
                border: 0;
                z-index: 12;
                transform: translate(-50%, -50%);
                line-height: 1;
            }
            &:focus {
                outline: none;
            }
        }
        .btn-prev {
            i {
                transform: rotate(180deg) translate(50%, -50%);
                transform-origin: 50% 0;
            }
        }
        .btn-next {
            left: auto;
            right: 0;
            background-image: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
        }
    }
}

.slick-prev,
.slick-next {
    // display: none;
    position: absolute;
    top: auto;
    bottom: 35px;
    width: auto;
    left: 20px;
    border: 0;
    z-index: 12;
    background: none;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%);
    i {
        position: absolute;
        top: 50%;
        left: 50%;
        font-size: 18px;
        background: none;
        color: $white;
        border: 0;
        z-index: 12;
        transform: translate(-50%, -50%);
        line-height: 1;

        @include media('>=tablet') {
          font-size: 60px;
        }
    }
    &:focus {
        outline: none;
    }

    @include media('>=tablet') {
        display: block;
        top: 0;
        bottom: 0;
        left: 0;
        width: 200px;
    }
}

.slick-prev {
    i {
        transform: rotate(180deg) translate(50%, -50%);
        transform-origin: 50% 0;
    }
}

.slick-next {
    left: auto;
    right: 20px;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 100%); 

    @include media('>=tablet') {
      right: 0;
    }
  }

.custom-pagination {
    @extend %listreset;
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translate(-50%);
    z-index: 20;
    color: $white;
    font-size: 14px;
    line-height: 1.57;
    letter-spacing: 0.7px;
    z-index: 10;
    text-align: center;
    li {
        display: none;
        &.slick-active {
            display: block;
        }
    }
}

@include media('>=desktop') {
    .portfolio__wrap.active-block .custom-pagination {
        font-size: 20px;
    }
}

#fp-nav {
    bottom: 90px;
    left: calc(50% - 23px);
    right: auto !important;
    top: auto !important;
    transform: translateX(96px);
    li {
        display: none !important;
        span {
            display: none !important;
        }
        &:nth-child(2) {
            display: block !important;
            width: 40px;
            height: 50px;
        }
    }
}

.servies-list {
    @extend %listreset;
    padding: 0 20px;
    position: relative;
    @include media('>=tablet') {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 33px 28px;
        min-height: 100vh;
        align-content: flex-start;
    }
    @include media('>=desktop') {
        padding: 71px 52px 0;
    }
    &:after {
        @include media('>=tablet') {
            content: '';
            background: url('../images/wood.svg') no-repeat;
            background-size: 100% auto;
            position: absolute;
            width: 398px;
            height: 255px;
            bottom: 0;
            right: 0;
            opacity: 0.05;
        }
    }
    &__item {
        font-size: 16px;
        font-weight: 300;
        line-height: 1.5;
        width: 100%;
        position: relative;
        counter-increment: step-counter;
        padding: 0 0 12px;
        margin: 0 0 41px;
        break-inside: avoid-column;
        -webkit-column-break-inside: avoid;
        @include media('>=tablet') {
            padding: 0 0 0 50px;
            flex-basis: 48%;
            width: 48%;
            margin: 0 0 15px;
        }
        @include media('>=desktop') {
            padding: 0 30px 0 50px;
            margin: 0 0 67px;
            flex-basis: 31%;
            width: 31%;
        }
        &:after {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            height: 1px;
            width: 40px;
            background: $grey;
            @include media('>=tablet') {
                display: none;
            }
        }
        &:last-child {
            margin: 0 0 28px;
            &:after {
                display: none;
            }
        }
        &:before {
            content: counter(step-counter);
            color: $gold;
            font-size: 80px;
            font-weight: 100;
            line-height: 0.33;
            text-align: right;
            position: absolute;
            left: -20px;
            top: 20px;
            display: none;
            width: 60px;
            @include media('>=tablet') {
                display: block;
            }
        }
    }
    &__title {
        font-size: 16px;
        font-weight: 500;
        line-height: 1.63;
        margin: 0 0 16px;
        color: $grey;
        text-transform: uppercase;
    }
}

.fp-scroller {
    overflow: hidden;
}

.fp-scrollable {
    overflow: hidden;
    position: relative;
}

.price-holder {
    @include media('>=tablet') {
        padding: 40px;
    }
    @include media('>=desktop') {
        padding: 84px 97px 0;
    }
}

.price-block {
    background: #f2f2f2;
    &:last-child {
        margin: 0 0 60px;
    }
    @include media('>=tablet') {
        background: $white;
        border: 1px solid #e6e6e6;
        border-width: 0 1px;
    }
    &__group {
        @include media('>=tablet') {
            position: relative;
            .price-block__block {
                h3 {
                    padding-left: 50px;
                }
            }
        }
        &__title {
            display: none;
            @include media('>=tablet') {
                position: absolute;
                width: 50px;
                top: 0;
                bottom: 0;
                left: 0;
                background-color: #f2f2f2;
                display: flex;
                text-transform: uppercase;
                font-size: 12px;
                font-weight: 500;
                align-items: center;
                border-bottom: 1px solid #e6e6e6;
            }
            span {
                display: table;
                white-space: nowrap;
                -webkit-transform: rotate(-90deg) translateY(-51px);
                -webkit-transform-origin: 50% 50%;
                transform: rotate(-90deg) translateY(-51px);
                transform-origin: 50% 50%;
                letter-spacing: 0.4px;
            }
        }
    }
    &__title {
        background: $grey;
        color: $white;
        padding: 10px 20px;
        letter-spacing: 0.7px;
        @include media('>=tablet') {
            background: #f2f2f2;
            padding: 17px 20px;
        }
        @include media('>=desktop') {
            padding: 15px 20px;
        }
        h2 {
            color: $white;
            margin: 0;
            font-size: 12px;
            font-weight: 500;
            line-height: 1.83;
            text-transform: uppercase;
            @include media('>=tablet') {
                color: $grey;
            }
        }
    }
    &__block {
        border-bottom: 1px solid $white;
        padding: 14px 20px 30px;
        font-weight: 300;
        color: $grey;
        &--title {
            display: none;
        }
        @include media('>=tablet') {
            display: flex;
            border-bottom: 1px solid #f2f2f2;
            padding: 11px 20px 15px;
            &--title {
                padding: 11px 20px 9px;
                .price-block__small-title {
                    display: block;
                    font-size: 12px;
                    line-height: 2.17;
                }
            }
        }
        @include media('>=desktop') {
            padding: 14px 20px 15px;
            &--title {
                padding: 11px 20px 9px;
            }
        }
        h3 {
            font-size: 16px;
            font-weight: 300;
            line-height: 1.25;
            margin: 0 0 9px;
            color: $grey;
            &.price-block__small-title {
                color: rgba($black, 0.5);
            }
            @include media('>=tablet') {
                flex-basis: 65%;
                margin: 0;
                font-size: 14px;
                line-height: 1.57;
            }
        }
    }
    &__info {
        display: flex;
        @include media('>=tablet') {
            flex-basis: 35%;
        }
        .col {
            width: 50%;
        }
    }
    &__small-title {
        display: block;
        margin: 0 0 -1px;
        font-size: 10px;
        font-weight: 300;
        line-height: 2.6;
        text-transform: uppercase;
        color: rgba($black, 0.5);
        @include media('>=tablet') {
            display: none;
        }
    }
}

.contact-block {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.5;
    padding: 0 20px 100px;
    display: flex;
    flex-wrap: wrap;
    @include media('>=tablet') {
        display: block;
        overflow: hidden;
        padding: 0 calc(50% + 40px) 0 40px;
        min-height: calc(100vh - 50px);
    }
    @include media('>=desktop') {
        display: block;
        overflow: hidden;
        padding: 0 calc(50% + 97px) 0 97px;
    }
    .address {
        font-style: normal;
        flex-basis: 100%;
        margin: -6px 0 28px;
        overflow: hidden;
        @include media('>=tablet') {
            flex-basis: 50%;
            padding: 40px 0 28px;
            margin: 0;
            br {
                display: none;
            }
        }
        p {
            margin: 0 0 24px;
        }
        a {
            display: inline-block;
            margin: 0 10px 20px 0;
        }
    }
}

.contact-form {
    flex-basis: 100%;
    order: 3;
    @include media('>=tablet') {
        flex-basis: 50%;
        padding: 20px 0;
        overflow: hidden;
        margin-top: -20px;
    }
    .success {
        display: none;
    }
    &.success-form .success {
        display: block;
        @include media('>=tablet') {
            display: block;
            position: absolute;
            top: 50%;
            margin: -40px 0 0;
        }
    }
    &.success-form .form {
        display: none;
    }
    .inp {
        position: relative;
        padding-right: 5px;
        &.focus {
            label {
                top: -17px;
                font-size: 13px;
                font-weight: 300;
                color: rgba(51, 51, 51, 0.5);
            }
        }
    }
    label {
        position: absolute;
        top: 5px;
        left: 0;
        z-index: 99;
        transition: all 0.3s ease;
    }
    .input-error {
        label {
            color: red;
        }
        .contact-form__text {
            color: red;
        }
    }
    &__text {
        display: block;
        width: 100%;
        margin: 0 0 19px;
        @include placeholder {
            color: white !important;
        }
        .focus & {
            @include placeholder {
                color: $placeholder-color !important;
            }
        }
        &--area {
            resize: none;
            height: 112px;
            margin: 0;
            padding: 0 !important;
            border: 0 !important;
        }
    }
    // .area {
    // 	border-bottom: 1px solid #000;
    // 	@include media('>=tablet') {
    // 		border: 0;
    // 	}
    // }
    .jcf-textarea {
        display: block;
        width: 100%;
        margin: 0 0 19px;
        border-bottom: 1px solid #000;
    }
    &__btn {
        color: $white;
        background: $grey;
        border: 0;
        font-size: 14px;
        font-weight: 500;
        width: 100%;
        line-height: 50px;
        text-transform: uppercase;
        height: 50px;
        &:hover,
        &:active {
            background: $gold;
        }
        &:focus {
            outline: none;
        }
        @include media('>=tablet') {
            width: 200px;
        }
    }
}

.map {
    flex-basis: calc(100% + 40px);
    height: 420px;
    order: 2;
    margin: 0 -20px 50px;
    width: calc(100% + 40px);
    @include media('>=tablet') {
        width: 50%;
        float: right;
        margin: 0 0 0 40px;
        position: absolute;
        top: 0;
        bottom: 50px;
        right: 0;
        flex-basis: 50%;
        height: calc(100% - 50px);
    }
}

.social {
    position: absolute;
    bottom: 150px;
    left: 0;
    right: 0;
    z-index: 109;
    @include media('>=tablet') {
        position: fixed;
        left: 0;
        width: 200px;
        bottom: 50px;
    }
    ul {
        @extend %listreset;
        display: flex;
        font-size: 22px;
        line-height: 1;
        justify-content: center;
        align-items: center;
    }
    li {
        margin: 0 10px;
    }
    a {
        color: #999;
        &:hover,
        &:focus,
        &:active {
            text-decoration: none;
            color: #333;
        }
    }
    .icon-fb {
        font-size: 20px;
    }
    .icon-vk {
        font-size: 30px;
    }
}

.check {
    padding: 12px 0 26px;
    label {
        display: block;
        align-items: center;
        color: #999 !important;
        font-weight: 300;
        line-height: 1.29;
        position: relative;
        top: 0;
        left: 0;
        z-index: 9;
        span {
            span {
                color: $gold;
            }
            ;
        }
    }
    &__field {
        z-index: 999;
        position: absolute;
        width: 16px;
        height: 16px;
        cursor: pointer;
        left: -9999px;
    }
    &__field:checked~&__box:after {
        display: block
    }
    &__box {
        width: 20px;
        height: 20px;
        flex: 0 0 auto;
        border: solid 1px $grey;
        display: inline-block;
        vertical-align: middle;
        margin: 0 10px 0 0;
        position: relative;
        &:after {
            content: "";
            width: 14px;
            height: 9px;
            border: 2px solid $gold;
            border-width: 3px 3px 0 0;
            transform: rotate(135deg);
            position: absolute;
            top: 2px;
            left: 2px;
            display: none;
        }
    }
    &.input-error .check__box {
        border-color: red;
    }
}

.input-error {
    position: relative;
    &:after {
        content: "\e905";
        position: absolute;
        top: 10px;
        right: 10px;
        color: red;
        font-family: 'icomoon' !important;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        /* Better Font Rendering =========== */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}

.jcf-scrollable-wrapper {
    width: 100% !important;
}

.jcf-scrollbar-vertical {
    position: absolute;
    cursor: default;
    width: 4px;
    bottom: 0;
    right: -5px;
    top: 0;
    border-radius: 0;
}

.jcf-scrollbar.jcf-scrollbar-vertical .jcf-scrollbar-slider {
  background: #fff; 
  padding: 2px 0;
}

.jcf-scrollbar-vertical .jcf-scrollbar-dec,
.jcf-scrollbar-vertical .jcf-scrollbar-inc {
    background: #ccc;
    height: 0;
    width: 14px;
    left: 0;
    top: 0;
}

.jcf-scrollbar-vertical .jcf-scrollbar-inc {
    top: auto;
    bottom: 0;
}

.jcf-scrollbar-vertical .jcf-scrollbar-handle {
  background: #ccc;
  background-color: rgba(0, 0, 0, 0.4);
  height: 1px;
  width: 4px;
  border-radius: 0; 
  transition: background-color 200ms ease; 
}

.jcf-scrollbar-vertical .jcf-scrollbar-handle:hover {
  background-color: rgba(0, 0, 0, 0.7);
  transition: background-color 200ms ease; 
}

.jcf-scrollbar.jcf-inactive .jcf-scrollbar-handle,
.jcf-scrollbar.jcf-inactive .jcf-scrollbar-slider {
    visibility: hidden;
}

.hidden {
    position: absolute;
    left: -9999px;
    top: -9999px;
}

.cookies {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 10px 40px 16px 20px;
    font-size: 13px;
    font-weight: 300;
    line-height: 1.62;
    color: $white;
    background: rgba(#807666, 0.75);
    z-index: 300;
    &:after {
        position: absolute;
        content: '';
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background: $black;
        z-index: -2;
    }
    &:before {
        position: absolute;
        content: '';
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background: rgba(#807666, 0.75);
        z-index: -1;
    }
    @include media('>=tablet') {
        left: 200px;
        padding: 10px 60px 16px 40px;
    }
    p {
        margin: 0;
    }
    &__close {
        position: absolute;
        top: 10px;
        right: 26px;
        font-size: 16px;
        color: $white;
        text-decoration: none;
        transition: all 0.3s ease;
        &:hover {
            text-decoration: none;
            transform: rotate(90deg);
        }
    }
}

@keyframes arroynext {
  0% {
    bottom: 110px; }
 
  50% {
    bottom: 90px; }

  100% {
    bottom: 110px; } 
}

.mask-nav {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: red;
}

html.fp-enabled,
.fp-enabled body {
    margin: 0;
    padding: 0;
    overflow: hidden;
    /*Avoid flicker on slides transitions for mobile phones #336 */
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.fp-section {
    position: relative;
    -webkit-box-sizing: border-box;
    /* Safari<=5 Android<=3 */
    -moz-box-sizing: border-box;
    /* <=28 */
    box-sizing: border-box;
}

.fp-slide {
    float: left;
}

.fp-slide,
.fp-slidesContainer {
    height: 100%;
    display: block;
}

.fp-slides {
    z-index: 1;
    height: 100%;
    overflow: hidden;
    position: relative;
    -webkit-transition: all 0.3s ease-out;
    /* Safari<=6 Android<=4.3 */
    transition: all 0.3s ease-out;
}

.fp-section.fp-table,
.fp-slide.fp-table {
    display: table;
    table-layout: fixed;
    width: 100%;
}

.fp-tableCell {
    display: table-cell;
    vertical-align: top;
    width: 100%;
    height: 100%;
}

.fp-slidesContainer {
    float: left;
    position: relative;
}

.fp-controlArrow {
    -webkit-user-select: none;
    /* webkit (safari, chrome) browsers */
    -moz-user-select: none;
    /* mozilla browsers */
    -khtml-user-select: none;
    /* webkit (konqueror) browsers */
    -ms-user-select: none;
    /* IE10+ */
    position: absolute;
    z-index: 4;
    top: 50%;
    cursor: pointer;
    width: 0;
    height: 0;
    border-style: solid;
    margin-top: -38px;
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.fp-controlArrow.fp-prev {
    left: 15px;
    width: 0;
    border-width: 38.5px 34px 38.5px 0;
    border-color: transparent #fff transparent transparent;
}

.fp-controlArrow.fp-next {
    right: 15px;
    border-width: 38.5px 0 38.5px 34px;
    border-color: transparent transparent transparent #fff;
}

.fp-scrollable {
    overflow: hidden;
    position: relative;
}

.fp-scroller {
    overflow: hidden;
}

.iScrollIndicator {
    border: 0 !important;
}

.fp-notransition {
    -webkit-transition: none !important;
    transition: none !important;
}

#fp-nav {
    position: fixed;
    z-index: 100;
    // margin-top: -32px;
    // top: 50%;
    // opacity: 1;
    // -webkit-transform: translate3d(0,0,0);
}

// #fp-nav.right {
//     right: 17px;
// }
#fp-nav.left {
    left: 17px;
}

.fp-slidesNav {
    position: absolute;
    z-index: 4;
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    left: 0 !important;
    right: 0;
    margin: 0 auto !important;
}

.fp-slidesNav.bottom {
    bottom: 17px;
}

.fp-slidesNav.top {
    top: 17px;
}

#fp-nav ul,
.fp-slidesNav ul {
    margin: 0;
    padding: 0;
}

#fp-nav ul li,
.fp-slidesNav ul li {
    display: block;
    width: 14px;
    height: 13px;
    margin: 7px;
    position: relative;
}

.fp-slidesNav ul li {
    display: inline-block;
}

#fp-nav ul li a,
.fp-slidesNav ul li a {
    display: block;
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    cursor: pointer;
    text-decoration: none;
}

#fp-nav ul li a.active span,
.fp-slidesNav ul li a.active span,
#fp-nav ul li:hover a.active span,
.fp-slidesNav ul li:hover a.active span {
    height: 12px;
    width: 12px;
    margin: -6px 0 0 -6px;
    border-radius: 100%;
}

#fp-nav ul li a span,
.fp-slidesNav ul li a span {
    border-radius: 50%;
    position: absolute;
    z-index: 1;
    height: 4px;
    width: 4px;
    border: 0;
    background: #333;
    left: 50%;
    top: 50%;
    margin: -2px 0 0 -2px;
    -webkit-transition: all 0.1s ease-in-out;
    -moz-transition: all 0.1s ease-in-out;
    -o-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
}

#fp-nav ul li:hover a span,
.fp-slidesNav ul li:hover a span {
    width: 10px;
    height: 10px;
    margin: -5px 0px 0px -5px;
}

#fp-nav ul li .fp-tooltip {
    position: absolute;
    top: -2px;
    color: #fff;
    font-size: 14px;
    font-family: arial, helvetica, sans-serif;
    white-space: nowrap;
    max-width: 220px;
    overflow: hidden;
    display: block;
    opacity: 0;
    width: 0;
    cursor: pointer;
}

#fp-nav ul li:hover .fp-tooltip,
#fp-nav.fp-show-active a.active+.fp-tooltip {
    -webkit-transition: opacity 0.2s ease-in;
    transition: opacity 0.2s ease-in;
    width: auto;
    opacity: 1;
}

#fp-nav ul li .fp-tooltip.right {
    right: 20px;
}

#fp-nav ul li .fp-tooltip.left {
    left: 20px;
}

.fp-auto-height.fp-section,
.fp-auto-height .fp-slide,
.fp-auto-height .fp-tableCell {
    height: auto !important;
}

.fp-responsive .fp-auto-height-responsive.fp-section,
.fp-responsive .fp-auto-height-responsive .fp-slide,
.fp-responsive .fp-auto-height-responsive .fp-tableCell {
    height: auto !important;
}