 $black: #000;
 $white: #fff;
 $red: #e42526;
 $grey: #333;
 $blue: #003e73;
 $gold: #8b754e;
 
 $primary-color:  $gold;

$base-bg: $white;
$base-color: $grey;
$font-size-base: 14px;
$line-height-base: 1.5;


$headings-font-family: 'Exo 2', sans-serif;
$headings-color: $black;

$base-link-color:  $primary-color;
$base-link-color-hover: $black;
$placeholder-color: $grey;

$btn-bg: $black;
$btn-color: $white;

